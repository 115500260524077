<template>
  <AdminView>
    <h1 class="title-1">Programmes recommandés</h1>

    <table>
      <thead>
        <tr>
          <th>Liste des pathologies</th>
          <th>Capacité fonctionnelle</th>
          <th class="width-program">Programmes</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(recommendProgram, i) in recommendedPrograms" :key="i">
          <td>{{ recommendProgram.pathology.name }}</td>
          <td>{{ capacityFunction(recommendProgram.functionalCapacity) }}</td>
          <td :key="i">
            <div class="program-tr">
              <div v-for="(program, j) in recommendProgram.programs" :key="j">
                <div
                  class="program"
                  :class="i % 2 === 0 ? 'program-background-1' : 'program-background-2'"
                >
                  <span> {{ program.name }} </span
                  ><span
                    @click="
                      openModalDelete(recommendProgram.recommendedProgramId, program.id)
                    "
                    class="x-program"
                    >x</span
                  >
                </div>
              </div>
            </div>
            <button class="button" @click="openModal(recommendProgram)">Ajouter</button>
          </td>
        </tr>
      </tbody>
    </table>
    <ConfirmationModal
      v-if="isOpenModalDelete"
      @close="isOpenModalDelete = false"
      @confirm="deleteProgram(recommendProgramId, idProgramToDelete)"
    />
    <Modal v-if="displayModal" :close="closeModal">
      <div class="row justify-end"><span class="close" @click="closeModal"></span></div>
      <div class="select-container">
        <Select
          title="Programmes :"
          name="program-pathologies"
          :options="options"
          v-model="selectedProgram"
        />
      </div>
      <div class="button-container">
        <button class="button" @click="validateProgram">Valider</button>
      </div>
    </Modal>
  </AdminView>
</template>

<script>
import AdminView from "@/components/AdminView"
import Modal from "@/components/Modal"
import Select from "@/components/Forms/Fields/Select"
import ConfirmationModal from "../../components/ConfirmationModal/ConfirmationModal.vue"

export default {
  name: "ProgramsList",
  components: {
    AdminView,
    Modal,
    Select,
    ConfirmationModal
  },
  data() {
    return {
      search: "",
      sortBy: "name",
      displayModal: false,
      recommendedPrograms: [],
      selectedProgram: null,
      options: [],
      programId: null,
      isOpenModalDelete: false,
      idProgramToDelete: 0,
      recommendProgramId: 0
    }
  },
  async created() {
    const response = await this.$store.dispatch("admin/getRecommendedPrograms")
    this.recommendedPrograms = response.recommendedPrograms
    const res = await this.$store.dispatch("admin/getPrograms")
    res.map((el) => this.options.push({ value: el.id, name: el.name }))
  },
  methods: {
    openModalDelete(recommendedProgramId, programId) {
      this.isOpenModalDelete = true
      this.recommendProgramId = recommendedProgramId
      this.idProgramToDelete = programId
    },
    async deleteProgram(recommendedProgramId, programId) {
      await this.$store.dispatch("admin/deleteRecommendedProgram", {
        recommendedProgramId: recommendedProgramId,
        programId: programId
      })
      const response = await this.$store.dispatch("admin/getRecommendedPrograms")
      this.isOpenModalDelete = false
      this.recommendedPrograms = response.recommendedPrograms
    },
    async validateProgram() {
      const data = {
        programId: this.selectedProgram,
        recommendedProgramId: this.programId
      }
      if (this.selectedProgram) {
        await this.$store.dispatch("admin/addRecommendedProgram", data)
      }
      this.closeModal()
      const response = await this.$store.dispatch("admin/getRecommendedPrograms")
      this.recommendedPrograms = response.recommendedPrograms
      this.selectedProgram = null
    },
    openModal(recommendProgram) {
      this.displayModal = true
      this.programId = recommendProgram.recommendedProgramId
    },
    closeModal() {
      this.displayModal = false
    },
    capacityFunction(capacity) {
      switch (capacity.index) {
        case 1:
          return "Assis"
        case 2:
          return "Debout"
        case 3:
          return "Marche intermédiaire"
        case 4:
          return "Marche autonome"
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-container {
  margin-bottom: 250px;
}
.button-container {
  display: flex;
  justify-content: center;
}
.button {
  background-color: $secondary-color;
  color: $white-color;
  border: 1px solid $secondary-color;
  border-radius: $border-radius;
  font-size: 1.25rem;
  font-weight: 500;
  padding: 5px 12px;
  cursor: pointer;
  margin: 0 10px;
  &:hover,
  &.active {
    color: $secondary-color;
    background-color: $white-color;
  }
}
.program-tr {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.width-program {
  min-width: 400px;
}
.x-program {
  color: red;
  font-size: 30px;
  margin-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}
.program {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 5px;
  border-radius: 20px;
}
.program-background-1 {
  background-color: #f0f0f0;
}
.program-background-2 {
  background-color: white;
}
.font-text {
  font-size: 30px;
}
.close {
  cursor: pointer;
  position: absolute;
  right: 9px;
  top: 3px;
  width: 28px;
  height: 7px;
  opacity: 0.3;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
.justify-end {
  position: relative;
  margin-bottom: 30px;
  justify-content: flex-end;
}
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px 0;

  div {
    flex: 0;
  }
}

.programs-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    padding: 8px 10px;
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
  }

  .image {
    padding: 2px;
    width: 50px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .icon {
    text-align: center;

    svg {
      width: 20px;
      color: $main-color;
    }
  }

  .edit {
    a {
      display: block;

      svg {
        width: 20px;
        transition: all 0.25s;
      }

      &:hover {
        svg {
          fill: $main-color;
        }
      }
    }
  }
}
</style>
